html,
body,
div#root {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  user-select: none;
  font-family: 'Pretendard';
}

*:focus {
  outline: none;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d3d7dc #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0;
  height: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d7dc;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b4bec5;
}
